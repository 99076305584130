<template>
    <v-container fluid no-gutters class="">
        <facebook-mvp :player="player" />
    </v-container>
</template>

<script>
    import FacebookMvp from '@/components/FacebookMvp'
    export default {
        components: { FacebookMvp },
        props: {
            match: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                stats: [
                    { text: 'Pts', value: 'points', team1: { player: null, points: 0 }, team2: { player: null, points: 0 } },
                    { text: '2P%', value: 'two_points_percentage', team1: { player: null, two_points_percentage: 0 }, team2: { player: null, two_points_percentage: 0 } },
                    { text: '3P%', value: 'three_points_percentage', team1: { player: null, three_points_percentage: 0 }, team2: { player: null, three_points_percentage: 0 } },
                    { text: 'FT%', value: 'free_throw_percentage', team1: { player: null, free_throw_percentage: 0 }, team2: { player: null, free_throw_percentage: 0 } },
                    { text: 'OR', value: 'offensiveRebounds', team1: { player: null, offensiveRebounds: 0 }, team2: { player: null, offensiveRebounds: 0 } },
                    { text: 'DR', value: 'defensiveRebounds', team1: { player: null, defensiveRebounds: 0 }, team2: { player: null, defensiveRebounds: 0 } },
                    { text: 'TR', value: 'rebounds', team1: { player: null, rebounds: 0 }, team2: { player: null, rebounds: 0 } },
                    { text: 'AST', value: 'assists', team1: { player: null, assists: 0 }, team2: { player: null, assists: 0 } },
                    { text: 'STL', value: 'steals', team1: { player: null, steals: 0 }, team2: { player: null, steals: 0 } },
                    { text: 'TO', value: 'turnovers', team1: { player: null, turnovers: 0 }, team2: { player: null, turnovers: 0 } },
                    { text: 'BLK', value: 'blockedShots', team1: { player: null, blockedShots: 0 }, team2: { player: null, blockedShots: 0 } },
                    { text: 'FC', value: 'personalFouls', team1: { player: null, personalFouls: 0 }, team2: { player: null, personalFouls: 0 } }
                ],
                assetsUrl: 'https://storage.googleapis.com',
                player: { stats: [{ pir: 0 }] }
            }
        },
        watch: {
            match: {
                handler() {
                    this.getMaxValues()
                    this.getMvp()
                }
            }
        },
        created() {
            this.getMaxValues()
            this.getMvp()
        },
        methods: {
            getMvp() {
                if (this.match.team1Score > this.match.team2Score) {
                    this.match.team1.players.forEach((player) => {
                        if (player.stats[0]) {
                            if (this.player.stats[0].pir < player.stats[0].pir) {
                                this.player = player
                            }
                        }
                    })
                } else {
                    this.match.team2.players.forEach((player) => {
                        if (player.stats[0]) {
                            if (this.player.stats[0].pir < player.stats[0].pir) {
                                this.player = player
                            }
                        }
                    })
                }
            },
            getMaxValues() {
                this.stats.forEach((stat) => {
                    this.match.team1.players.forEach((player) => {
                        if (player.stats.length > 0) {
                            player.stats[0].pir = (player.stats[0].points + player.stats[0].rebounds + player.stats[0].assists + player.stats[0].steals + player.stats[0].blockedShots) -
                                ((player.stats[0].twoPointAttempts + player.stats[0].threePointAttempts - player.stats[0].twoPointsMade - player.stats[0].threePointsMade) +
                                    (player.stats[0].freeThrowAttempts - player.stats[0].freeThrowsMade) + player.stats[0].turnovers + player.stats[0].personalFouls + player.stats[0].technicalFouls)
                            if (stat.value === 'two_points_percentage') {
                                if (stat.team1[stat.value] < (player.stats[0].twoPointsMade / player.stats[0].twoPointAttempts)) {
                                    stat.team1.player = player
                                    stat.team1[stat.value] = player.stats[0].twoPointsMade / player.stats[0].twoPointAttempts
                                }
                            } else if (stat.value === 'three_points_percentage') {
                                if (stat.team1[stat.value] < (player.stats[0].threePointsMade / player.stats[0].threePointAttempts)) {
                                    stat.team1.player = player
                                    stat.team1[stat.value] = player.stats[0].threePointsMade / player.stats[0].threePointAttempts
                                }
                            } else if (stat.value === 'free_throw_percentage') {
                                if (stat.team1[stat.value] < (player.stats[0].freeThrowsMade / player.stats[0].freeThrowAttempts)) {
                                    stat.team1.player = player
                                    stat.team1[stat.value] = player.stats[0].freeThrowsMade / player.stats[0].freeThrowAttempts
                                }
                            } else {
                                if (stat.team1[stat.value] < player.stats[0][stat.value]) {
                                    stat.team1.player = player
                                    stat.team1[stat.value] = player.stats[0][stat.value]
                                }
                            }
                        }
                    })
                    this.match.team2.players.forEach((player) => {
                        if (player.stats.length > 0) {
                            player.stats[0].pir = (player.stats[0].points + player.stats[0].rebounds + player.stats[0].assists + player.stats[0].steals + player.stats[0].blockedShots) -
                                ((player.stats[0].twoPointAttempts + player.stats[0].threePointAttempts - player.stats[0].twoPointsMade - player.stats[0].threePointsMade) +
                                    (player.stats[0].freeThrowAttempts - player.stats[0].freeThrowsMade) + player.stats[0].turnovers + player.stats[0].personalFouls + player.stats[0].technicalFouls)
                            if (stat.value === 'two_points_percentage') {
                                if (stat.team2[stat.value] < (player.stats[0].twoPointsMade / player.stats[0].twoPointAttempts)) {
                                    stat.team2.player = player
                                    stat.team2[stat.value] = player.stats[0].twoPointsMade / player.stats[0].twoPointAttempts
                                }
                            } else if (stat.value === 'three_points_percentage') {
                                if (stat.team2[stat.value] < (player.stats[0].threePointsMade / player.stats[0].threePointAttempts)) {
                                    stat.team2.player = player
                                    stat.team2[stat.value] = player.stats[0].threePointsMade / player.stats[0].threePointAttempts
                                }
                            } else if (stat.value === 'free_throw_percentage') {
                                if (stat.team2[stat.value] < (player.stats[0].freeThrowsMade / player.stats[0].freeThrowAttempts)) {
                                    stat.team2.player = player
                                    stat.team2[stat.value] = player.stats[0].freeThrowsMade / player.stats[0].freeThrowAttempts
                                }
                            } else {
                                if (stat.team2[stat.value] < player.stats[0][stat.value]) {
                                    stat.team2.player = player
                                    stat.team2[stat.value] = player.stats[0][stat.value]
                                }
                            }
                        }
                    })
                })
            }
        }
    }
</script>

<style>
    .v-tabs:not(.v-tabs--vertical).v-tabs--right > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__next,
    .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
        display: none;
    }
</style>

<style scoped>
    .text-h6 {
        font-size: 1.25rem !important;
        letter-spacing: 0.0125em !important;
        line-height: 2rem;
    }

    .bottom-border {
        border-bottom: 1px solid rgb(231, 225, 225);
    }
</style>
