<template>
    <v-container fluid no-gutters class="mx-0 px-0">
        <match-banner :match="match" />
        <facebook-post-match-details :class="$vuetify.breakpoint.mobile ? '' : 'px-16'" :match="match" />
    </v-container>
</template>

<script>
    import MatchBanner from '@/components/MatchBanner'
    import FacebookPostMatchDetails from '@/components/FacebookPostMatchDetails'
    import matchService from '@/services/match'

    export default {
        components: { MatchBanner, FacebookPostMatchDetails },
        data() {
            return {
                tab: 1,
                items: [
                    {
                        name: 'Στατιστικά Παιχνιδιού'
                    },
                    {
                        name: 'Στατιστικά Παιχτών'
                    },
                    {
                        name: 'Βίντεο Παιχνιδιού'
                    }
                ],
                match: {},
                assetsUrl: 'https://storage.googleapis.com'
            }
        },
        created() {
            this.fetchMatch(this.$route.params.id)
        },
        methods: {
            fetchMatch(matchId) {
                matchService.getMatch(matchId).then(resp => {
                    this.match = resp.data
                })
            },
            downloadStats() {
                window.open(`${this.assetsUrl}/${this.match.pdfUrl}`, '_blank')
            }
        }
    }
</script>

<style scoped>

.mobile-sticky-banner {
    position: sticky;
    top: 80px;
    z-index: 1;
}

.pc-sticky-banner {
    position: sticky;
    top: 60px;
    z-index: 1;
}

.v-tab {
    text-transform: none !important;
    font-weight: bolder;
    font-size: 15px;
    color: #757578 !important;
}

.v-tab::before {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    background-color: transparent;
}

.v-tab--active {
    color: orange !important;
}
</style>
