import api from '@/utils/api'

export default {
    getMatches(page, size, query) {
        let filter = null
        if (Object.values(query).length > 0) {
            filter = Object.values(query).reduce((total, value) => total + `, ${value}`)
        }
        const range = '?range=' + encodeURIComponent(`[${page - 1},${size}]`)
        return api.get('matches' + (page ? range : '') + (filter ? `&filter=${encodeURIComponent('{' + filter + '}')}` : ''))
    },

    getMatchesByTeam(id, query, phase) {
        let filter = (phase === 'cup' ? 'isCup=true' : '')
        if (query) {
            if (Object.values(query).length > 0) {
                filter = Object.values(query).reduce((total, value) => total + `&${value}`)
            }
        }
        const queryParams = (phase && phase !== 'cup') ? `?phase=${phase}&` : ('?' + filter ? `?${filter}` : '')
        return api.get(`matches/team/${id}` + queryParams)
    },

    getMatch(id) {
        return api.get(`matches/${id}/stats`)
    }
}
